export const menuDataIt= [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'museum',
    label: 'Il museo',
  },
  {
    path: 'about',
    label: 'Chi siamo',
  },
  {
    path: 'visit',
    label: 'Visite e Orari',
  },
  {
    path: 'where',
    label: 'Dove siamo'
  },
  {
    path: 'partners',
    label: 'Visita anche'
  }
];

export const menuDataEn= [
  {
    path: 'home',
    label: 'Home',
  },
  {
    path: 'museum',
    label: 'Museum',
  },
  {
    path: 'about',
    label: 'About Us',
  },
  {
    path: 'visit',
    label: 'Visit',
  },
  {
    path: 'where',
    label: 'Where'
  },
  {
    path: 'partners',
    label: 'Partners'
  }
];
