/** @jsx jsx */
import { jsx, Container, Flex } from 'theme-ui';
import { keyframes } from '@emotion/core';
import { Link } from 'react-scroll';
import { Link as LinkGatsby } from "gatsby"

import Logo from '../logo';
import LogoDark from '../../assets/logo_associazione.png';
import LogoParco from '../../assets/logoParco.png';
import { DrawerProvider } from '../../contexts/drawer/drawer.provider';
import MobileDrawer from './mobile-drawer';
import { menuDataEn, menuDataIt } from './header.data';

export default function Header({lang }) {

  const menuItems = (lang === 'en' ? menuDataEn : menuDataIt) || []
  return (
    <DrawerProvider>
      <header sx={styles.header} className={'sticky'} id="header">
        <Container sx={styles.container}>
          <Logo src={LogoDark} />
          <Logo src={LogoParco} path='https://lessiniapark.it' />

          <Flex as="nav" sx={styles.nav}>
            {menuItems.map(({ path, label }, i) => (
              <Link
                activeClass="active"
                to={path}
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                key={i}
              >
                {label}
              </Link>
            ))}

            <LinkGatsby
              activeClass="active"
              to={'/'}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/it.png`}
                srcSet={`https://flagcdn.com/w40/it.png 2x`}
                alt={`Flag of it`}
              />
            </LinkGatsby>
            <LinkGatsby
              activeClass="active"
              to={'/en'}
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/gb.png`}
                srcSet={`https://flagcdn.com/w40/gb.png 2x`}
                alt={`Flag of en`}
              />
            </LinkGatsby>
          </Flex>

          <MobileDrawer />
        </Container>
      </header>
    </DrawerProvider>
  );
}

const positionAnim = keyframes`
  from {
    position: fixed;
    opacity: 1;
  }
  to {
    position: absolute;
    opacity: 1;
    transition: all 0.4s ease;
  }
`;

const styles = {
  header: {
    color: 'white',
    fontWeight: 'normal',
    py: 4,
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundColor: 'transparent',
    transition: 'all 0.5s ease',
    animation: `${positionAnim} 0.4s ease`,
    '&.sticky': {
      position: 'fixed',
      backgroundColor: 'background',
      color: '#000000',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.06)',
      py: 3,
      'nev > a': {
        color: 'text',
      },
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  nav: {
    mx: 'auto',
    display: 'none',
    '@media screen and (min-width: 1024px)': {
      display: 'block',
    },
    a: {
      fontSize: '16px',
      fontWeight: '400',
      px: 25,
      cursor: 'pointer',
      lineHeight: '1.2',
      '&.active': {
        color: 'secondary',
      },
    },
  },
};
