/** @jsx jsx */
import { jsx, Grid, Container, Text, Flex, IconButton } from 'theme-ui';
import { AiFillPhone, AiFillMail, AiFillFacebook } from 'react-icons/ai';
import { Link } from '../link';

export default function Footer() {
  return (
    <footer sx={styles.footer}>
      <Container sx={styles.footer.container}>
        <Grid gap="15px 0" columns={[1, null, 3]}>
          <Flex sx={{ mb: 1, justifyContent: 'center' }} >
            <IconButton sx={styles.icon}>
              <AiFillPhone />
            </IconButton>
            <Link sx={styles.link} href="tel:0456516005">045 6516005</Link>
          </Flex>
          <Flex sx={{ mb: 1, justifyContent: 'center' }} >
            <IconButton sx={styles.icon}>
              <AiFillMail />
            </IconButton>
            <Link sx={styles.link} href="mailto:fossili.camposilvano@libero.it">fossili.camposilvano@libero.it</Link>
          </Flex>
          <Flex sx={{ mb: 1, justifyContent: 'center' }} >
            Get in touch:
            <Link path="https://www.facebook.com/MuseoCamposilvano/" target="_blank"  sx={styles.link} >
              <IconButton sx={{ ...styles.icon, ml: 2 , cursor: 'pointer' }} >
                <AiFillFacebook />
              </IconButton>
            </Link>
          </Flex>
        </Grid>
      </Container>
      <Text sx={styles.footer.copyright}>
        Copyright © 2022 Museo Geopaleontologico di Camposilvano
      </Text>
    </footer>
  );
}

const styles = {
  icon: {
    width: [25, '35px'],
    height: 'auto',
    //color: 'primary',
    padding: 0,
    fontSize: [2, 5],
    marginLeft: '-1px',
    flexShrink: 0,
    justifyContent: 'flex-start',
    marginTop: [1, '2px'],
  },
  footer: {
    pt: 5,
    backgroundColor: 'primary',
    color: '#fff',

    container: {
      width: '100%',
      alignItems: 'stretch',
    },

    footerTopArea: {
      borderTop: '1px solid',
      borderTopColor: 'border_color',
      display: 'flex',
      flexWrap: 'wrap',
      pt: [7, null, 8],
      pb: ['10px', null, null, '20px'],
      px: [0, null, null, null, 4],
    },
    menus: {
      width: ['50%', null, null, '25%'],
      display: 'flex',
      flexDirection: 'column',
      mb: ['40px', null, null, '60px'],
    },

    heading: {
      fontSize: [3, null, null, 4],
      color: 'text_secondary',
      fontWeight: '500',
      mb: [3, 4, 5, null, 6],
      lineHeight: '1.35',
    },

    link: {
      fontSize: ['14px', null, 1],
      color: 'text',
      fontWeight: 'body',
      mb: 2,
      cursor: 'pointer',
      transition: 'all 0.35s',
      display: 'block',
      textDecoration: 'none',
      lineHeight: [1.5, null, null, 1.6, 1.8],
      ':hover': {
        color: 'primary',
      },
      ':last-child': {
        mb: '0px',
      },
    },
    copyright: {
      fontSize: ['14px', null, 1],
      width: '100%',
      textAlign: 'center',
      p: ['20px 20px'],
     // backgroundColor: '#FCFDFE',
      //color: 'text',
      a: {
        textDecoration: 'none',
        color: 'inherit',
        pl: 1,
        transition: 'all 0.25s',
        '&:hover': {
          color: 'primary',
        },
      },
    },
  },
  link: {
    color: '#fff',
    textDecoration: 'none',
    mx: 2
  }
};
