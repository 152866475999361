/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Sticky from 'react-stickynode';
import Header from './header/header';
import Footer from './footer/footer';

export default function Layout({ children, lang }) {
  return (
    <React.Fragment>
      <Sticky innerZ={1001} top={0}>
        <Header lang={lang} 
        />
      </Sticky>
      <main
        sx={{
          variant: 'layout.main',
        }}
      >
        {children}
      </main>
    <Footer />
    </React.Fragment>
  );
}
